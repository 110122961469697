@import 'bootstrap/normalize';

html {
	box-sizing: border-box;
}

* {
	&:focus { outline: 0; }

	&, &::before, &::after {
		box-sizing: inherit;
	}
}

ol, ul {
	display: block;
	padding: 0;
	margin: 0;

	list-style: none;
}

ul {
	li {
		display: block;
	}
}

a {
	text-decoration: none;
}
