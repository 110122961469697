@import 'common/variables';

@import 'bootstrap/variables';
@import 'grid/reset';
@import 'bootstrap/mixins';
@import 'bootstrap/responsive-utilities';

@import 'bootstrap/grid';
@import 'common/mixins';

@include viewport-eq(xs) {
	.col-xs-clear {
		clear: left;
	}
}

@include viewport-eq(sm) {
	.col-sm-clear {
		clear: left;
	}
}

@include viewport-eq(md) {
	.col-md-clear {
		clear: left;
	}
}

@include viewport-eq(lg) {
	.col-md-clear {
		clear: left;
	}
}
